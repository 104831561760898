<template>
<div class="klubba-loader-icon" :class="{ 'is-active': active, 'theme-light': light }" />
</template>

<script>
export default {
  name: 'KlubbaLoaderIcon',
  props: {
    active: {
      type: Boolean,
    },
    light: {
      type: Boolean,
    },
  },
};
</script>

<style lang="sass">
.klubba-loader-icon
  @apply w-6 h-6 rounded-full opacity-0 absolute left-0 top-0 right-0 bottom-0 m-auto
  border: 0.2rem solid rgba(0, 0, 27, 0.3)
  border-top-color: rgb(0, 0, 27)
  animation: 1.5s custom-spin infinite linear
  transition: opacity var(--transition)

  [lazy="loading"]:not([src]) + &, &.is-active
    @apply opacity-100

  &.theme-light
    border-color: rgba(white, .3)
    border-top-color: white
</style>
